@use '../../styles/vars' as v;

.home {
  position: relative;
  height: 100vh;
  margin-top: - v.$marginTopMobile;

  @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
      margin-top: - v.$marginTopTablet;
  }

  @media screen and (min-width: v.$minDesktopBreakpoint) {
      margin-top: - v.$marginTopDesktop;
  }


  &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("./../../assets/images/home_first.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 1; 
      transition: opacity 2s ease-in-out, blur 2s ease-in-out;
  }

  &__opacity::before {
      opacity: 0.3;
      filter: blur(5px);
  }
}

  
  .fullpage-navigation {
    position: fixed;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
  }
  
  .fullpage-navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .fullpage-navigation ul li {
    margin: 10px 0;
  }
  
  .fullpage-navigation ul li a {
    display: block;
    width: 10px;
    height: 10px;
    background-color: #000;
    border-radius: 50%;
    text-indent: -9999px; // Cache le texte
    transition: background-color 0.3s;
  }
  
  .fullpage-navigation ul li a.active {
    background-color: #fff;
  }
  



  
$primaryColor: #A79277;
$secondaryColor: #D1BB9E;
$tertiaryColor: #cea849;
$quaternaryColor: #FFF2E1;


$maxMobileBreakpoint: 767px;

$minTabletBreakpoint: 768px;
$maxTabletBreakpoint: 1440px;

$minDesktopBreakpoint: 1441px;
$maxDesktopBreakpoint: 1919px;

$minLargeDesktopBreakpoint: 1920px;

$minBorderRadius: 8px;
$maxBorderRadius: 15px;

$marginTopMobile: 4em;
$marginTopTablet: 8em;
$marginTopDesktop: 10em;


.myButton {
    display: inline-block;
    margin: 0 auto;
    background-color: white;
    color: $primaryColor;
    border-radius: $minBorderRadius;
    padding: .5em 1em;
    font-size: 1.1em;
    text-decoration: none;
    border: none;
    cursor: pointer;
    &:hover {
        background: $secondaryColor;
        color: white;
    }
};

.mySmallButton {
    display: block;
    background: #ffffff;
    border-radius: $minBorderRadius;
    padding: .2em .5em;
    font-size: .8em;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background: #fff;
        color: $primaryColor;
        border: 1px solid $primaryColor;
    }

    @media (max-width: $maxMobileBreakpoint) {
        display: block;
        background: $secondaryColor;
        color: #fff;
        border-radius: $minBorderRadius;
        padding: .1em .4em;
        font-size: .6em;
        text-decoration: none;
        border: none;
    }
};


@use '../../styles/vars' as v;

.about {

    @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
        margin-top: - v.$marginTopTablet;
        background-image: url( '../../assets/images/Laura/LauraRobe.png' );
        background-size: cover;
        background-position: top;
        padding: 2em;
        min-height: 100vh;
    }

    @media screen and (min-width: v.$minDesktopBreakpoint) {
        margin-top: - v.$marginTopDesktop;
        background-image: url( '../../assets/images/Laura/LauraRobe.png' );
        background-size: cover;
        background-position: top;
        padding: 2em;
        min-height: 100vh;
    }

    &__image {
        width: 90vw;

        @media screen and (min-width: v.$minTabletBreakpoint) {
            display: none;
            margin: 0;
        }
    
    }

    &__title {
        font-size: 1.6em;
        color: white;
        background-color: v.$primaryColor;
        line-height: .8em;
        padding: .9em 0 .4em;
        margin-bottom: .5em;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            font-size: 2em;
            background-color: transparent;
            text-align: right;
            margin-top: 3em;
            width: 40%;
        }
    
        @media screen and (min-width: v.$minDesktopBreakpoint) {
            font-size: 2em;
            background-color: transparent;
            text-align: right;
            margin-top: 3em;
            width: 40%;
        }
    }

    &__text {
        padding: 2em;
        display: flex;
        flex-direction: column;


        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            flex-direction: row;
            justify-content: space-between;
            font-size: 1.3em;
            line-height: 1.8em;
        }
    
        @media screen and (min-width: v.$minDesktopBreakpoint) {
            flex-direction: row;
            justify-content: space-between;
            font-size: 1.3em;
            line-height: 1.8em;
        }

        &__part {
            @media screen and (min-width: v.$minTabletBreakpoint) {
                width: 35%;
            }
        }
    }
}

@use '../../../styles/vars' as v;

$heightImageMobile: 350px;
$heightImageTablet: 350px;
$heightImageDesktop: 300px;

.galleries {
    width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px; /* espace entre les cards */

    @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media screen and (min-width: v.$minDesktopBreakpoint) {
        width: 80vw;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px; /* espace entre les cards */
    }

    &__title {
        margin-bottom: 1em;
    }

    &__card {
        width: 95vw;
        box-sizing: border-box;
        background-color: v.$quaternaryColor;
        border: 1px solid v.$primaryColor;
        padding: 1em;
        color: black;
        text-decoration: none;
        border-radius: v.$minBorderRadius;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            width: 35%;
            height: 520px;
        }

        @media screen and (min-width: v.$minDesktopBreakpoint) {
            width: 22%;
            height: 480px;
        }

        &:hover {
            @media screen and (min-width: v.$minTabletBreakpoint) {
                background-color: v.$tertiaryColor;
                color: white;
                transition: all .5s ease-in-out;
                transform: scale(1.1);
            }
        }

        &__body {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__image {
            display: block;
            margin: 0 auto;
            height: $heightImageMobile;
            object-fit: cover;
            border-radius: v.$minBorderRadius;
            margin-bottom: 1em;
            width: 100%;
            
            @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
                height: $heightImageTablet;
            }
            @media screen and (min-width: v.$minDesktopBreakpoint) {
                height: $heightImageDesktop;
            }

            &__placeholder {
                width: 100%;
                height: $heightImageMobile;
                background-color: v.$secondaryColor;
                border-radius: v.$minBorderRadius;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
                    height: $heightImageTablet;
                }

                @media screen and (min-width: v.$minDesktopBreakpoint) {
                    height: $heightImageDesktop;
                }

                &__text {
                    color: white;
                }
            }
        }
    }
}

  

  

  





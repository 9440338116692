@use '../../../styles/vars' as v;

.image {
    position: relative;

    &__action {
        position: absolute;
        top: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 44px;
        height: 44px; 
        background-color: v.$tertiaryColor; 
        color: white;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: width 0.3s ease; 
        overflow: hidden; 
        margin: 0 auto 2em;
        font-weight: bold;

        &.favorite {
            color: v.$tertiaryColor;
            background-color: white;
        }

        &.delete {
            color: red;
            background-color: white;

        }

        &__icon {
            margin-left: 2px;
            font-size: 1.7em;

            &__check {
                margin-left: 2px;
                font-size: 1.7em;
                color: green;
            }
        }
    
        &__text {
            opacity: 0; 
            margin-left: 5px;
            white-space: nowrap;
            transition: opacity 0.3s ease;
            font-size: 1.1em;
        }
    
        &:hover {
            width: 220px; 
    
            & .image__action__text {
                opacity: 1; 
            }
        }
    
        & i {
            transition: margin-right 0.3s ease;
        }
    } 
}








@use '../../styles/vars' as v;

.navbar {

    &__nav {
        background-color: v.$secondaryColor;
        width: 100%;
        margin-top: .5em;
        border-radius: v.$minBorderRadius;
        box-shadow: 0 0 10px 0 v.$secondaryColor;
    }

    @media screen and (min-width: v.$minTabletBreakpoint) {
        background-color: transparent;
        width: 100%;
        float: none;
        margin-top: 0;
        box-shadow: none;       
    }
    
    &__link {
        letter-spacing: .2em;
        color: white !important;
        

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
            cursor: pointer;

            &:hover {
                border-bottom: 3px solid v.$primaryColor;
            }
        }
    
        @media (min-width: v.$minDesktopBreakpoint) {
            cursor: pointer;

            &:hover {
                border-bottom: 3px solid v.$primaryColor;
            }
        }

        &__logo {
            display: none !important;

            @media screen and (min-width: v.$minTabletBreakpoint) {
                display: block !important;
            }
        }

        &__home {
            display: block !important;

            @media screen and (min-width: v.$minTabletBreakpoint) {
                display: none !important;
            }
        }

        &__dropdown .dropdown-menu {
            background-color: v.$primaryColor;
            letter-spacing: normal;
            width: 65vw;
            text-align: center;
            margin: .5em 0;
            padding: .5em;

            @media screen and (min-width: v.$minTabletBreakpoint) {
                background-color: v.$secondaryColor;
                width: 100%;
            }         
        }

        &__dropdown__link {
            color: white !important;
            cursor: pointer;

            @media screen and (min-width: v.$minTabletBreakpoint) {
                letter-spacing: .2em;
                font-size: 1.1em;
                
            }

            &:hover {
                color: v.$secondaryColor !important;
                background-color: white !important;
            }

        }
    }

    &__logo {

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
            width: 230px;
        }
    
        @media (min-width: v.$minDesktopBreakpoint) {
            width: 270px;
        }
    }

    &__scroll {
        background-color: v.$primaryColor;
    }
}


.active {
    border-bottom: 3px solid v.$primaryColor;

    @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
    }

    @media (min-width: v.$minDesktopBreakpoint) {
    }
}

#basic-nav-dropdown {
    color: white;
    cursor: pointer;

    @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
    }

    @media (min-width: v.$minDesktopBreakpoint) {
    }
}

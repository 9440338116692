@use '../../styles/vars' as v;

.changePassword {
    padding: 1rem;
    width: 400px;
    margin: 1rem auto;
    border-radius: v.$minBorderRadius;

    &__title {
        text-align: center;
        font-size: 1.3rem;
        text-decoration: underline;
        font-weight: bold;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__input {
            margin: .1em;
            width: 100%;
            padding: .5rem;
            border: 1px solid v.$primaryColor;
            border-radius: v.$minBorderRadius;
            font-size: 1rem;
            font-family: 'Frank Ruhl Libre', serif;
            letter-spacing: 1px;
        }
    }

    @media (max-width: v.$maxMobileBreakpoint) {
        width: 100%;
    }
  
    &__alert {
        display: flex;
        justify-content: space-between;

    }
}
@use '../../styles/vars' as v;

.contact {
  
  &__intro {
    margin: 2em 17vw;
    font-size: 1.3em;

    @media (max-width: v.$maxMobileBreakpoint) {
      margin: 1em 0;
      text-align: center;
      font-size: 1.1em;
    }
  }

  &__content{
    display: flex;
    justify-content: center;

    @media (max-width: v.$maxMobileBreakpoint) {
      flex-direction: column;
    }

    &__form {
      width: min-content;

      @media (max-width: v.$maxMobileBreakpoint) {
        width: 100%;
      }

      &__input {
        width: 50vw;
        display: block;
        margin: .5em auto;
        padding: .5em;
        border: 1px solid #ccc;
        border-radius: v.$minBorderRadius;
        font-size: 1em;

        @media (max-width: v.$maxMobileBreakpoint) {
          width: 95%;
        }
      }

      &__textarea {
        display: block;
        width: 50vw;
        margin: .5em auto 2em;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: v.$minBorderRadius;
        font-size: 1em;
        height: 7em;

        @media (max-width: v.$maxMobileBreakpoint) {
          width: 95%;
        }
      }

      &__button {
        width: 100% !important;
        color: black !important;

        @media (max-width: v.$maxMobileBreakpoint) {
          display: block !important;
          width: 95% !important;
          margin: 0 auto !important;
        }

      }
    }

    &__right {
      width: fit-content;
      padding: 0 4em;
      text-align: center;
      margin: auto 0;

      @media (max-width: v.$maxMobileBreakpoint) {
        width: 100%;
        padding: 0;
        margin-top: 2em;
      }

      &__name {
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      &__address {
        margin-bottom: -.2em;
      }

      &__tel {
        margin: 1.5em 0 -.2em;
      }

      &__email {
        color: white;
        font-size: 1.1em;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
        // on hover
        &:hover {
          text-decoration-color: v.$primaryColor;
          color: v.$secondaryColor;
          text-decoration-thickness: 5px;
          text-underline-offset: 8px;
        }
      }

      &__siret {
        margin-top: 1.5em;
      }
    }
  }
}

@use '../../styles/vars' as v;

.newGallery{

    &__button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 44px;
        height: 44px; 
        background-color: v.$secondaryColor; 
        color: white;
        border: none;
        border-radius: 25px;
        cursor: pointer;
        transition: width 0.3s ease; /* Transition sur la largeur */
        overflow: hidden; /* Cache le texte quand il n'est pas survolé */
        margin: 0 auto 2em;

        & .button-text {
            opacity: 0; /* Texte masqué par défaut */
            margin-left: 10px;
            white-space: nowrap;
            transition: opacity 0.3s ease;
        }

        &:hover {
            width: 180px; /* Largeur du bouton au survol */

            & .button-text {
                opacity: 1; /* Afficher le texte au survol */
            }
        }

        & i {
            transition: margin-right 0.3s ease;
        }
    }
}
  
  
  


  

  

  





@use '../../../styles/vars' as v;

.welcome {
  display: flex;
  padding: .5em;
  flex-direction: column;

  @media screen and (min-width: v.$minTabletBreakpoint) {
    flex-direction: row;
    align-items: center;
    padding: 4em;
  }


  &__left {
    width: 90%;
    margin: 0 auto;

    @media screen and (min-width: v.$minTabletBreakpoint) {
      width: 50%;
      margin-right: 1em;
    }
  }

  &__right {
    margin-top: 1em;
    padding: 1em;

    
    @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
      margin-top: 0;
      text-align: justify;
    }

    @media (min-width: v.$minDesktopBreakpoint) {
      padding: 4em 6em 4em 2em;
      margin-top: 0;
      text-align: justify;
    }

    &__title {
      font-size: 1.8em;
      margin-bottom: .3em;
      font-weight: bold;

      @media screen and (min-width: v.$minTabletBreakpoint) {
        font-size: 3em;
        text-align: center;
      }
    }

    &__text {
      font-size: 1em;
      line-height: 1.6em;
      letter-spacing: .1em;

      @media screen and (min-width: v.$minTabletBreakpoint) {
        font-size: 1.3em;
        line-height: 1.6em;
      }
    }
  }
}
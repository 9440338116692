@use '../../../styles/vars' as v;

.sidebar {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 500;

    @media screen and (max-width: v.$maxMobileBreakpoint) {
      display: none;
    }

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      cursor: pointer;

      &__dot {
        width: 10px;
        height: 10px;
        background-color: white;
        margin-right: 8px;
      }

      &__label {
        color: white;
      }

      &__active .sidebar__item__dot {
        background-color: v.$quaternaryColor;
      }
    
    }
  }
  
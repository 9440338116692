@use '../../../styles/vars' as v;

.favourites {
            width: 22vw;
            background-color: white;
            padding: 1em;
            margin-left: 1em;
            border-radius: v.$minBorderRadius;
            overflow: hidden;
            overflow-y: auto;
            height: 70vh;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    
            &__favourite {
                display: flex;
                align-items: center;
                margin: .5em 0;
                cursor: pointer;
                color: black;

                &__photo {
                    width: 100px;
                    margin: .5em;
                }
            }
        
    }

   






@use 'src/styles/vars' as v;

.account {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
    font-size: 1.3em;
    text-decoration: underline;
    font-weight: bold;
    margin-bottom: .4em;
  }

  
}
@use '../../styles/vars' as v;
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@400;700&family=Roboto+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=WindSong:wght@400;500&display=swap');


.app {
    min-height: 100vh;
    background-color: v.$primaryColor;
    //font-family: 'Poiret One', 'Frank Ruhl Libre', sans-serif;
    font-family: "Red Hat Display", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__body {
        margin-top: v.$marginTopMobile;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            margin-top: v.$marginTopTablet;
        }

        @media screen and (min-width: v.$minDesktopBreakpoint) {
            margin-top: v.$marginTopDesktop;
        }

    }
} 

.customTitle {
    font-family: 'Poiret One', 'Frank Ruhl Libre', sans-serif;
}
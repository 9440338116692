@use '../../../styles/vars' as v;


.shooting {

    &__header {
        margin-bottom: 1em;
        text-align: center;

        &__title {
            font-family: "WindSong", serif;
            font-weight: bold;

            &.short {
                font-size: 5em;

                @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
                    font-size: 8em;
                }
            
                @media screen and (min-width: v.$minDesktopBreakpoint) {
                    font-size: 12em;
                }
            }

            &.long {
                font-size: 3em;

                @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
                    font-size: 5em;
                }

                @media screen and (min-width: v.$minDesktopBreakpoint) {
                    font-size: 9em;
                }
            }

            
        }

        &__name {
            font-size: 1.5em;
            letter-spacing: .1em;

            @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
                font-size: 1.8em;
                letter-spacing: .2em;
            }
        
            @media screen and (min-width: v.$minDesktopBreakpoint) {
                font-size: 2.1em;
                letter-spacing: .3em;
            }
        }

        &__number {
            font-size: 1.8em;
            background-color: v.$secondaryColor;
            padding: .2em .4em;
            border-radius: 50%;
            font-weight: bold;
        }

        &__text {
            margin-left: .5em;
            font-size: 1.2em;
            text-decoration: underline;
            text-decoration-color: v.$secondaryColor;
            text-decoration-thickness: .2em;
            text-underline-offset: .4em;
            letter-spacing: .1em;
        }
    }
    

    &__heart {
        display: block;
        font-size: 1.5em;
        text-align: right;
        margin-bottom: 1em;
        margin-right: .5em;
        
        &:hover {
            cursor: pointer;
        }
    }
    
    
}
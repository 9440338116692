@use '../../../styles/vars' as v;


.firstPart {

    &__bigTitle{
        font-size: 1.6em;
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-decoration: underline;
        text-underline-offset: 0.5em;
        text-decoration-thickness: 1px;
        text-transform: uppercase;
        letter-spacing: .1em;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
            font-size: 2em;
          }

        @media (min-width: v.$minDesktopBreakpoint) {
            font-size: 3.8em;
        }
    }

    &__subTitle{
        font-size: 1em;
        letter-spacing: .15em;
        margin: 1em;


        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint) {
            font-size: 1.5em;
          }

        @media (min-width: v.$minDesktopBreakpoint) {
            font-size: 2em;
        }
    }
}
@use '../../styles/vars' as v;

.infos {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    text-align: justify;

    @media screen and (min-width: v.$minTabletBreakpoint) {
        flex-direction: row;
        padding: 2em;

    }

    &__text {
        padding: 0.2em 1em;
        line-height: 1.6em;
        z-index: 2;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            font-size: 1.1em;

        }
        
        @media screen and (min-width: v.$minDesktopBreakpoint) {
            font-size: 1.2em;
        }

        &__intro {
            background-color: v.$secondaryColor;
            padding: 1em;
            font-style: italic;
            line-height: 1.7em;
            border-radius: v.$minBorderRadius;
            text-align: center;
            margin-bottom: 1em;
            position: relative;
            margin: 1.5em 0;

            @media screen and (min-width: v.$minTabletBreakpoint) {
                width: 80%;
                transform: translateX(40%);
            }

            &::before {
                content: '\00AB';
                font-size: 4.5em;
                position: absolute;
                top: -15px;
                left: -15px;
                color: white;
                color: v.$quaternaryColor;
                opacity: 0.5;
            }

            &::after {
                content: '\00BB';
                font-size: 4.5em;
                position: absolute;
                top: 86px;
                left: 328px;
                color: white; 
                color: v.$quaternaryColor;
                opacity: 0.5;

                @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
                    top: 84%;
                    left: 94%;
                }

                @media screen and (min-width: v.$minDesktopBreakpoint) {
                    top: 70%;
                    left: 95.5%;
                }
            }
        }

        &__question {
            padding: .5em;
            font-style: bold;
            opacity: 0.7;
            text-decoration: underline;
            text-underline-offset: 1em;
            text-decoration-color: v.$quaternaryColor;
            text-decoration-thickness: 3px;
            margin-top: 1em;  

        }
        
    }

    &__image {
        width: 80%;
        border-radius: v.$minBorderRadius;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            width: 40%;
            transform: translateX(-80px);
            z-index: 1;

        }

        @media screen and (min-width: v.$minDesktopBreakpoint) {
            width: 45%;
            transform: translateX(-100px);
            z-index: 1;

        }
    }
    
}
@use 'src/styles/vars' as v;

.newClient {
  display: flex;
  flex-direction: column;
  padding-top: 10em;
    &__title {
      text-align: center;
      }
    &__form {
        width: 50%;
        max-width: 700px;
        margin: 0 auto;


        &__input {
            width: 100%;
            padding: .3em;
            font-size: 1em;
            border: 1px solid #ddd;
            border-radius: v.$minBorderRadius;
            height: 2.5em;
            margin-top: .5em;

            &:focus {
              border-color: v.$primaryColor;

              & + .field__label {
                color: v.$primaryColor;
              }
            }
        
        }
        &__buttons {
            display: flex;
            justify-content: center;
            
        }
    }
}



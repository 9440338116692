@use '../../../styles/vars' as v;

.about-home {
    margin: 0 auto;
    padding: 1.2em;
    border-radius: v.$maxBorderRadius;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: v.$minTabletBreakpoint) {
        max-width: 80vw;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    

    &__title {
        font-size: 1.4em;
        margin-bottom: 1em;
        font-weight: bold;
        width: 70%;
        margin: 0 auto .5em;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            font-size: 2em;
        }
        
        @media (min-width: v.$minDesktopBreakpoint) {
            font-size: 3em;
        }
    }

    & p {
        line-height: 1.5em;
        letter-spacing: .1em;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            font-size: 1.2em;
            line-height: 1.6em;
        }
        
        @media (min-width: v.$minDesktopBreakpoint) {
            font-size: 1.4em;
            line-height: 1.7em;
        }
    }

    &__image {
        width: 150px;
        object-fit: cover;
        border-radius: 50%;
        float: right;
        shape-outside: margin-box;
        margin: 0 auto;

        @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
            width: 300px;
            margin: 3em;
        }
        
        @media (min-width: v.$minDesktopBreakpoint) {
            width: 400px;
            margin: 3em;
        }
    }    
    
}
@use 'src/styles/vars' as v;

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: black;

    @media screen and (min-width: v.$minTabletBreakpoint) and (max-width: v.$maxTabletBreakpoint){
      margin-top: - v.$marginTopTablet;
    }

    @media screen and (min-width: v.$minDesktopBreakpoint) {
      margin-top: - v.$marginTopDesktop;
    }
  
  &__form {
    background-color: v.$primaryColor;
    padding: 2em;
    border-radius: v.$minBorderRadius;
    width: 90%;
    color: white;

    &__intro {
      font-size: 1.1em;
      text-align: center;
      margin-bottom: 1em;
    }

    &__input {
      width: 100%;
      padding: .5em;
      border: none;
      border-radius: v.$minBorderRadius;
      height: 3em;
      margin: .8em 0;
      background-color: v.$secondaryColor;
      color: white;
      
      &::placeholder {
        color: white;
        font-size: 1.1em;
        text-align: center;
      }

      &:focus {
        border-color: v.$primaryColor;

        & + .field__label {
          color: v.$primaryColor;
        }
      }
    }
  }

  &__error {
    color: red;
    font-size: 1em;
    margin-top: .5em;
    text-align: center;
  }
}



.forgotPassword__form {
  border: v.$primaryColor solid 3px;
  text-align: center;
  padding: 4em 0;
  margin: auto;
  margin-top: 3em;
  border-radius: v.$minBorderRadius;
  max-width: 500px;
  background-color: white;

  &__input {
    width: 70%;
    padding: .5em;
    border: none;
    border-radius: v.$minBorderRadius;
    height: 3em;
    margin: .8em 0;
    background-color: v.$primaryColor;
    color: white;
    
    &::placeholder {
      color: white;
      font-size: 1.1em;
      text-align: center;
    }

    &:focus {
      border-color: v.$primaryColor;

      & + .field__label {
        color: v.$primaryColor;
      }
    } 
  }
}



@use '../../styles/vars' as v;

.addPicture {
    text-align: center;
    color: white;

    &__form {
        margin: 1em 0;
    }

   
    &__form-container {
        width: 80%;
        margin: 0 auto;
        background-color: #dadada;
        border-radius: v.$minBorderRadius;
        margin-bottom: 2em;
        padding: .5em 1em;
        text-align: left;

        &__images {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2em .5em;
            flex-wrap: wrap;
            margin-top: 1em;

            &__item {
                height: 250px;
            }
        }
    }

    
}
@use 'src/styles/vars' as v;

.changeTemporaryPassword {
  width: 80%;
  margin: 2rem auto;
  margin-bottom: 4rem;
  padding-top: 13em;

  &__description {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


}
